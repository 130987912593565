import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import affiliation from 'assets/pdf/1_cbse.pdf';
import trust from 'assets/pdf/Prathana_trust-min.pdf';
import noc from 'assets/pdf/3_NOC.pdf';
import cbseRequest from 'assets/pdf/CBSE-REQUEST-LATTER_26-10-2017-min.pdf';
import buildingSaftey from 'assets/pdf/5_Bulding_Safety_Certificate.pdf';
import fireSaftey from 'assets/pdf/Fire_Sefty_Cetificate-min.pdf';
import deoSign from 'assets/pdf/7_DEO LETTER.pdf';
import sanitary from 'assets/pdf/8_Water.pdf';
import fees from 'assets/pdf/FEES_STRUCTURE_2023-24-min.pdf';
import last3result from 'assets/pdf/LAST_3_YEAR_CBSE_BOARD_RESULT-min.pdf';
import ptaMember from 'assets/pdf/PTA_MEMBER-min.pdf';
import admissionForm from 'assets/pdf/Addmission_Foorm-min.pdf';
import schoolCommitee from 'assets/pdf/SCHOOL_MANAGING_COMMITTI_2023-24-min.pdf';
import tc from 'assets/pdf/TC.pdf';
import cbseDisclosure from 'assets/pdf/16_Mandatory.pdf';

import calendar from 'assets/pdf/11_Academic_Calender.pdf';
import examtimetable from 'assets/pdf/Exam_Time_Table_rotated-min.pdf';
import listofholiday from 'assets/pdf/holidayList-min.pdf';
import cca from 'assets/pdf/5_ListOfCCA-min.pdf';
import nationaleducationpolicy from 'assets/pdf/6_NEP-min.pdf';

const PdfViewer = () => {
	const [numPages, setNumPages] = useState<number>(0);
	const [mapperIndex, setMapperIndex] = useState<number>(0);

	const { id } = useParams();

	const mapper = [
		affiliation,
		trust,
		noc,
		cbseRequest,
		buildingSaftey,
		fireSaftey,
		deoSign,
		sanitary,
		admissionForm,
		fees,
		calendar,
		schoolCommitee,
		ptaMember,
		last3result,
		tc,
		cbseDisclosure,
		listofholiday,
		cca,
		nationaleducationpolicy,
		examtimetable
	];

	const onDocumentLoadSuccess = ({ numPages }: any) => {
		setNumPages(numPages);
	};

	useEffect(() => {
		// Load the PDF worker dynamically
		pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
		setMapperIndex(Number(id));
		window.scrollTo(0, 0);
	}, [id]);

	return (
		<div className='mt--50'>
			<Document file={mapper[mapperIndex]} onLoadSuccess={onDocumentLoadSuccess}>
				{Array.from(new Array(numPages), (el, index) => (
					<Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} />
				))}
			</Document>
		</div>
	);
};

export default PdfViewer;
