import { useEffect, lazy, Suspense } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

import PdfViewer from 'shared/component/pdfViewer';
import Layout from 'shared/hoc/layout/component/layout';
import Footer from 'shared/hoc/layout/component/footer';
import { DISABLE_DEV_TOOLS } from 'shared/constant/constant';

import 'assets/styles/index.scss';

// Import the components using React.lazy() function
const HomePageContainer = lazy(() => import('./homePage/container/homePageContainer'));
const AboutUs = lazy(() => import('./features/aboutUs/component/aboutUs'));
const CbseMandatoryDisclosure = lazy(() => import('features/aboutUs/component/cbseMandatoryDisclosure'));
const MissionVision = lazy(() => import('features/aboutUs/component/missionVision'));
const ChairmanIntro = lazy(() => import('features/aboutUs/component/chairmanIntro'));
const Director = lazy(() => import('features/aboutUs/component/director'));
const RulesRegulation = lazy(() => import('features/aboutUs/component/rulesRegulation'));
const SchoolSystem = lazy(() => import('features/aboutUs/component/schoolSystem'));
const CoCurricularActivity = lazy(() => import('features/activity/component/coCurricularActivity'));
const SportsAndGames = lazy(() => import('features/activity/component/sportsAndGames'));
const Art = lazy(() => import('features/activity/component/art'));
const Music = lazy(() => import('features/activity/component/music'));
const Dance = lazy(() => import('features/activity/component/dance'));
const HousingAndDining = lazy(() => import('features/activity/component/housingAndDining'));
const ContactUs = lazy(() => import('homePage/component/contactUs'));
const Awards = lazy(() => import('features/awards/component/awards'));
const Tc = lazy(() => import('features/academics/component/tc'));
const TcImageViewer = lazy(() => import('shared/component/tcImageViewer'));
const Training = lazy(() => import('features/academics/component/training'));
const VideoGallery = lazy(() => import('features/gallery/component/videoGallery'));
const PhotoGallery = lazy(() => import('features/gallery/component/photoGallery'));

function App() {
	useEffect(() => {
		DISABLE_DEV_TOOLS();
	}, []);

	return (
		<BrowserRouter>
			<Layout>
				<Suspense fallback={<div>Loading...</div>}>
					<Routes>
						<Route path='*' element={<Navigate replace to='/' />} />
						<Route path='/' element={<HomePageContainer />} />
						<Route path='/mission&vision' element={<MissionVision />} />
						<Route path='/chairman' element={<ChairmanIntro />} />
						<Route path='/director' element={<Director />} />
						<Route path='/aboutschool' element={<AboutUs />} />
						<Route path='/rules&regulation' element={<RulesRegulation />} />
						<Route path='/schoolsystem' element={<SchoolSystem />} />
						<Route path='/cbsemandatorydiscolsure' element={<CbseMandatoryDisclosure />} />
						<Route path='/cbsemandatorydiscolsure/:id' element={<PdfViewer />} />
						<Route path='/fees/:id' element={<PdfViewer />} />
						<Route path='/training' element={<Training />} />
						<Route path='/academics/:id' element={<PdfViewer />} />
						<Route path='/transfer-certificate' element={<Tc />} />
						<Route path='/transfer-certificate/:id' element={<TcImageViewer />} />
						<Route path='/cocurricularactivity' element={<CoCurricularActivity />} />
						<Route path='/sports&game' element={<SportsAndGames />} />
						<Route path='/art' element={<Art />} />
						<Route path='/music' element={<Music />} />
						<Route path='/dance' element={<Dance />} />
						<Route path='/housing&dining' element={<HousingAndDining />} />
						<Route path='/awards' element={<Awards />} />
						<Route path='/contactUs' element={<ContactUs />} />
						<Route path='/photo-gallery' element={<PhotoGallery />} />
						<Route path='/video-gallery' element={<VideoGallery />} />
					</Routes>
				</Suspense>
				<Footer />
			</Layout>
		</BrowserRouter>
	);
}

export default App;
