import { ReactNode } from 'react';
import { TopHeader } from 'shared/component/navigation/topHeader';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';

interface IParentComponentProps {
	children: ReactNode;
}
const Layout: React.FC<IParentComponentProps> = (props) => {
	return (
		<div className='container'>
			<Analytics />
			<SpeedInsights />
			{<TopHeader />}
			{props.children}
		</div>
	);
};

export default Layout;
