import { useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import AnimatedNotification from './animatedNotification';
import useOnClickOutside from 'shared/hoc/onclickOutSide';

import headerLogo from 'assets/image/logo.jpg';
import MiniNavbar from './miniNavbar';

export const TopHeader = () => {
	const navigate = useNavigate();
	const [isPopUpOpen, setIsPopUpOpen] = useState(false);
	const closePopRef = useRef<HTMLDivElement>(null);

	const handleOnClosePopUp = useCallback(() => {
		setIsPopUpOpen(false);
	}, []);

	useOnClickOutside(closePopRef, handleOnClosePopUp);

	const SUB_MENU_STYLE = 'cursor--pointer font-size--sm mb--5 p--5';

	return (
		<div
			ref={closePopRef}
			className='flex width--full justify__content--between align__items--center top--0 position--sticky header-bg pb--5 z-index--1'
		>
			<div className='width--10-per cursor--pointer mr--15' onClick={() => navigate('/')}>
				<img src={headerLogo} alt='prarthana school' className='header-logo' />
			</div>
			<div className='flex width--90-per justify__content--between align__items--center navbar-menu'>
				<div className='header'>
					<ul className='flex no--padding'>
						<li className='mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
							<Link to='/' className='mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
								<span>Home</span>
							</Link>
						</li>
						<li className='has-dropdown mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
							<Link to='/' className='pointer--none mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
								<span>About Us</span>
							</Link>
							<ul className='dropdown'>
								<Link to='/aboutschool' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>About Prarthana School</span>
									</li>
								</Link>
								<Link to='/mission&vision' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Mission & vision</span>
									</li>
								</Link>
								<Link to='/chairman' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/chairman')}>
										<span>Chairman's Message</span>
									</li>
								</Link>
								<Link to='/director' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/director')}>
										<span>Director's Message</span>
									</li>
								</Link>
								<Link to='/rules&regulation' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/rules&regulation')}>
										<span>Rules & Regulation</span>
									</li>
								</Link>
								<Link to='/schoolsystem' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/schoolsystem')}>
										<span>School System</span>
									</li>
								</Link>
								<Link to='/cbsemandatorydiscolsure' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>CBSE Mandatory Disclosure</span>
									</li>
								</Link>
							</ul>
						</li>
						<li className='has-dropdown mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
							<Link to='/' className='pointer--none mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
								<span>Academics</span>
							</Link>
							<ul className='dropdown'>
								<Link to='/training' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Training</span>
									</li>
								</Link>
								<Link to='/transfer-certificate' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Transfer Certificate</span>
									</li>
								</Link>
								<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/academics/16')}>
									<span>List Of Holidays</span>
								</li>
								<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/academics/10')}>
									<span>Academic Calendar</span>
								</li>
								<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/academics/17')}>
									<span>CCA & Competitions</span>
								</li>
								<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/academics/18')}>
									<span>National Education Policy</span>
								</li>
								<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/academics/19')}>
									<span>Exam Time Table</span>
								</li>
								<li className={`${SUB_MENU_STYLE}`} onClick={() => navigate('/fees/9')}>
									<span>Fees</span>
								</li>
							</ul>
						</li>
						<li className='has-dropdown mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
							<Link to='/' className='pointer--none mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
								<span>Activity</span>
							</Link>
							<ul className='dropdown'>
								<Link to='/cocurricularactivity' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Co-Curricular Activities</span>
									</li>
								</Link>
								<Link to='/sports&game' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Sports & Game</span>
									</li>
								</Link>
								<Link to='/art' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Art Room</span>
									</li>
								</Link>
								<Link to='/music' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Music Room</span>
									</li>
								</Link>
								<Link to='/dance' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Dance Room</span>
									</li>
								</Link>
								<Link to='/housing&dining' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Housing & Dining</span>
									</li>
								</Link>
							</ul>
						</li>
						<li className='has-dropdown mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
							<Link to='/' className='pointer--none mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
								<span>Gallery</span>
							</Link>
							<ul className='dropdown'>
								<Link to='/photo-gallery' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Photos</span>
									</li>
								</Link>
								<Link to='/video-gallery' className='text-decoration--none link-text--blue'>
									<li className={`${SUB_MENU_STYLE}`}>
										<span>Videos</span>
									</li>
								</Link>
							</ul>
						</li>
						<li className='mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
							<Link to='/awards' className='mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
								<span>Awards</span>
							</Link>
						</li>
						<li className='mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
							<Link to='/contactUs' className='mr--40 text-decoration--none link-text--blue font-size--16px font-weight--600'>
								<span>Contact Us</span>
							</Link>
						</li>
					</ul>
				</div>
				<span className='cursor--pointer' onClick={() => setIsPopUpOpen(!isPopUpOpen)}>
					<AnimatedNotification />
				</span>
				<button
					className='primary--btn ml--30 font-weight--600 no--border cursor--pointer admission--btn'
					onClick={() => navigate('/admission')}
					tabIndex={-1}
				>
					Admission
				</button>
			</div>
			<MiniNavbar />
			{isPopUpOpen && (
				<div className='position--absolute notification--popup b-radius--25 pl--20 pr--20'>
					<p className='font-weight--600'>
						ADMISSIONS OPEN FOR 2024-25
						<br /> <span className='text-decoration--underline'>OFFICE TIMING:</span> MON-SAT 8 AM TO 1 PM, SUNDAY 10 AM TO 1 PM.
					</p>
				</div>
			)}
		</div>
	);
};
