import { useState } from 'react';
import MiniMenuList from './miniMenuList';

const MiniNavbar = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	return (
		<div className='mini-menu--container d-none'>
			<div className={`mini-menu--icon position--relative cursor--pointer ${isMenuOpen ? 'd-none' : ''}`} onClick={() => setIsMenuOpen(true)}>
				<div className='bar1' />
				<div className='bar2' />
				<div className='bar3' />
			</div>
			<div className={`mini-navbar--box overflow--auto position--absolute width--300-px height--100vh ${!isMenuOpen ? 'd-none' : ''}`}>
				<div className='close-bar--wrapper' onClick={() => setIsMenuOpen(false)}>
					<div className='close-bar1' />
					<div className='close-bar2' />
				</div>
				<MiniMenuList setIsMenuOpen={setIsMenuOpen} />
			</div>
		</div>
	);
};

export default MiniNavbar;
