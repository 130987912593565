import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MENU_ITEMS } from 'shared/constant/constant';

interface IMenuItemsProps {
	setIsMenuOpen: (isMenuOpen: boolean) => void;
}
const MiniMenuList: React.FC<IMenuItemsProps> = (props) => {
	const { setIsMenuOpen } = props;
	const navigate = useNavigate();
	const [submenuOpen, setSubmenuOpen] = useState<{ [key: string]: boolean }>({});

	const toggleSubmenu = (menu: string) => {
		setSubmenuOpen({
			...submenuOpen,
			[menu]: !submenuOpen[menu]
		});
	};
	return (
		<div className='mini-menu-list--container flex mt--50 mb--30'>
			<ul className='flex flex--column line-height--60px overflow--auto'>
				{MENU_ITEMS.map((menuItems) => (
					<li
						className='text-decoration--none link-text--blue font-size--17 font-weight--600 list-type--none cursor--pointer'
						key={menuItems.label}
						onClick={() => toggleSubmenu(menuItems.label)}
					>
						{menuItems.subitems.length === 0 ? (
							<Link to={menuItems.link} className='text-decoration--none link-text--blue' onClick={() => setIsMenuOpen(false)}>
								{menuItems.label}
							</Link>
						) : (
							<span className='text-decoration--none link-text--blue'>{menuItems.label}</span>
						)}
						{submenuOpen[menuItems.label] && (
							<ul className='line-height--40px pl--20'>
								{menuItems.subitems.map((subitem) => (
									<li
										key={subitem.label}
										className='text-decoration--none link-text--blue font-size--11 font-weight--600 list-type--none cursor--pointer '
										onClick={() => {
											navigate(subitem.link), setIsMenuOpen(false);
										}}
									>
										{subitem.label}
									</li>
								))}
							</ul>
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default MiniMenuList;
