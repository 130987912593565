import campusImgOne from 'assets/image/new_school/SCHOOL_FRONT_VIEWPNG-min.png';
import campusImgTwo from 'assets/image/new_school/prathana_edited.jpg';
import campusImgThree from 'assets/image/new_school/1school-min.jpg';
import campusImgFour from 'assets/image/new_school/2school-min.jpg';
import campusImgFive from 'assets/image/new_school/3school-min.jpg';
import campusImgSix from 'assets/image/new_school/4school-min.jpg';
import campusImgSeven from 'assets/image/new_school/5school-min.jpg';
import campusImgNine from 'assets/image/new_school/7school.jpg';
import Badmintoon from 'assets/image/New_activity/badminton-min.jpg';
import Basket_Ball from 'assets/image/New_activity/Basket_Ball-min.jpg';
import shooting from 'assets/image/New_activity/shooting.jpg';
import Volly_Ball from 'assets/image/New_activity/Volly_Ball-min.jpg';
import Foot_Ball from 'assets/image/New_activity/Foot_Ball-min.jpg';
import activity from 'assets/image/New_activity/activity-min.jpg';
import BIO_LAB from 'assets/image/New_activity/BIO-LAB-min.jpg';
import CHM_LAB_1 from 'assets/image/New_activity/CHM-LAB-min.jpg';
import Computer_Lab from 'assets/image/New_activity/Computer-Lab-min.jpg';
import Maths_Lab from 'assets/image/New_activity/Maths-Lab-min.jpg';
import Maths_Lab_2 from 'assets/image/New_activity/Maths-lab2-min.jpg';
import Montisory_Lab from 'assets/image/New_activity/Montisory-Lab-min.jpg';
import PHY_LAB from 'assets/image/New_activity/PHY-LAB-min.jpg';
import Teacher_Trannig from 'assets/image/New_activity/Teacher_Trannig-min.jpg';
import kabbadi from 'assets/image/New_activity/Kabbadi-min.jpg';
import election_1 from 'assets/image/photo_gallery/election_1.jpg';
import election_2 from 'assets/image/photo_gallery/election_2.jpg';
import election_3 from 'assets/image/photo_gallery/election_3.jpg';
import election_4 from 'assets/image/photo_gallery/election_4.jpg';
import election_5 from 'assets/image/photo_gallery/election_5.jpg';
import election_6 from 'assets/image/photo_gallery/election_6.jpg';
import election_7 from 'assets/image/photo_gallery/election_7.jpg';
import election_8 from 'assets/image/photo_gallery/election_8.jpg';
import election_9 from 'assets/image/photo_gallery/election_9.jpg';
import election_10 from 'assets/image/photo_gallery/election_10.jpg';
import oath_1 from 'assets/image/photo_gallery/oath_1.jpg';
import oath_2 from 'assets/image/photo_gallery/oath_2.jpg';
import oath_3 from 'assets/image/photo_gallery/oath_3.jpg';
import oath_4 from 'assets/image/photo_gallery/oath_4.jpg';
import oath_5 from 'assets/image/photo_gallery/oath_5.jpg';

const IMAGE_SCHOOL = [
	{ image: campusImgFive },
	{ image: campusImgTwo },
	{ image: campusImgThree },
	{ image: campusImgFour },
	{ image: campusImgSix },
	{ image: campusImgOne },
	{ image: campusImgSeven },
	{ image: campusImgNine }
];

const FACILITY_Details = [
	{ image: Badmintoon, facilityName: 'Badminton Court' },
	{ image: Basket_Ball, facilityName: 'Basket Ball Court' },
	{ image: shooting, facilityName: 'Shooting' },
	{ image: Volly_Ball, facilityName: 'Volly Ball Court' },
	{ image: kabbadi, facilityName: 'kabbadi Ground' },
	{ image: Foot_Ball, facilityName: 'Foot Ball Ground' },
	{ image: activity, facilityName: 'Art & Carft Room' },
	{ image: PHY_LAB, facilityName: 'Physics Lab' },
	{ image: BIO_LAB, facilityName: 'Biology Lab' },
	{ image: CHM_LAB_1, facilityName: 'Chemistry Lab' },
	{ image: Maths_Lab, facilityName: 'Maths Lab' },
	{ image: Maths_Lab_2, facilityName: 'Maths Lab' },
	{ image: Computer_Lab, facilityName: 'Computer Lab' },
	{ image: Montisory_Lab, facilityName: 'Montisory Lab' },
	{ image: Teacher_Trannig, facilityName: 'Teacher Trannig' }
];

const SPORTS_DETAILS = [
	{ image: Foot_Ball, facilityName: 'Foot Ball Ground' },
	{ image: Badmintoon, facilityName: 'Badminton Court' },
	{ image: Basket_Ball, facilityName: 'Basket Ball Court' },
	{ image: shooting, facilityName: 'Shooting' },
	{ image: Volly_Ball, facilityName: 'Volly Ball Court' },
	{ image: kabbadi, facilityName: 'kabbadi Ground' }
];

const ART_CRAFT_DETAILS = [{ image: activity, facilityName: 'Art & Carft Room' }];

const ELECTION_GALLERY = [
	{ image: election_1 },
	{ image: election_2 },
	{ image: election_3 },
	{ image: election_4 },
	{ image: election_5 },
	{ image: election_6 },
	{ image: election_7 },
	{ image: election_8 },
	{ image: election_9 },
	{ image: election_10 }
];

const OATH_GALLERY = [{ image: oath_1 }, { image: oath_2 }, { image: oath_3 }, { image: oath_4 }, { image: oath_5 }];

const FACILITY_SLIDER_SETTINGS = {
	dots: true,
	infinite: true,
	// arrows: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	centerMode: true,
	centerPadding: '-40px',
	responsive: [
		{
			breakpoint: 880,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2
			}
		},
		{
			breakpoint: 500,
			settings: {
				dots: false,
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 1
			}
		},
		{
			breakpoint: 320,
			settings: {
				dots: false,
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 1
			}
		}
	]
	// autoplay: true,
	// speed: 8000,
	// autoplaySpeed: 0,
	// pauseOnHover: false,
	// cssEase: 'linear'
};

const DISABLE_DEV_TOOLS = () => {
	const disableContextMenu = (event: any) => {
		event.preventDefault();
	};
	const disableRightClick = (event: any) => {
		if (event.ctrlKey && event.shiftKey && (event.keyCode === 73 || event.keyCode === 74)) {
			event.preventDefault();
		}
		if (event.code === 'F12') {
			event.preventDefault();
		}
	};

	document.addEventListener('keydown', disableRightClick);
	document.addEventListener('contextmenu', disableContextMenu);

	return () => {
		document.removeEventListener('contextmenu', disableContextMenu);
		document.removeEventListener('keydown', disableRightClick);
	};
};

const MENU_ITEMS = [
	{
		label: 'Home',
		subitems: [],
		link: '/'
	},
	{
		label: 'About Us',
		subitems: [
			{ label: 'About Prarthana School', link: '/aboutschool' },
			{ label: 'Mission & vision', link: '/mission&vision' },
			{ label: `Chairman's Message`, link: '/chairman' },
			{ label: `Director's Message`, link: '/director' },
			{ label: 'Rules & Regulation', link: '/rules&regulation' },
			{ label: 'School System', link: '/schoolsystem' },
			{ label: 'CBSE Mandatory Disclosure', link: '/cbsemandatorydiscolsure' }
		],
		link: ''
	},
	{
		label: 'Academics',
		subitems: [
			{ label: 'Training', link: '/training' },
			{ label: 'Transfer Certificate', link: '/transfer-certificate' },
			{ label: 'List Of Holidays', link: '/academics/16' },
			{ label: 'Academic Calendar', link: '/academics/10' },
			{ label: 'CCA & Competitions', link: '/academics/17' },
			{ label: 'National Education Policy', link: '/academics/18' },
			{ label: 'Exam Time Table', link: '/academics/19' },
			{ label: 'Fees', link: '/fees/9' }
		],
		link: ''
	},
	{
		label: 'Activity',
		subitems: [
			{ label: 'Co-Curricular Activities', link: '/cocurricularactivity' },
			{ label: 'Sports & Game', link: '/sports&game' },
			{ label: 'Art Room', link: '/art' },
			{ label: 'Music Room', link: '/music' },
			{ label: 'Dance Room', link: '/dance' },
			{ label: 'Housing & Dining', link: '/housing&dining' }
		],
		link: ''
	},
	{
		label: 'Gallery',
		subitems: [
			{ label: 'Photos', link: '/photo-gallery' },
			{ label: 'Videos', link: '/video-gallery' }
		],
		link: ''
	},
	{
		label: 'Awards',
		subitems: [],
		link: '/awards'
	},
	{
		label: 'Contact Us',
		subitems: [],
		link: '/contactUs'
	}
];
export {
	IMAGE_SCHOOL,
	FACILITY_SLIDER_SETTINGS,
	DISABLE_DEV_TOOLS,
	FACILITY_Details,
	SPORTS_DETAILS,
	ART_CRAFT_DETAILS,
	ELECTION_GALLERY,
	OATH_GALLERY,
	MENU_ITEMS
};
